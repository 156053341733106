/* @font-face {
  font-family: 'Poppins';
  src: url('../components/fonts/PoppinsMedium.woff2') format('woff2'),
  url('../components/fonts/PoppinsMedium.woff') format('woff');
  font-weight: 500;
  font-style: normal;

  font-display: swap;
}
@font-face {
  font-family: 'sst';
  src: url('../components/fonts/sst/SST-Roman.woff2') format('woff2'),
  url('../components/fonts/sst/SST-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: 'Poppins-black';
  src: url('../components/fonts/PoppinsBlack.woff2') format('woff2'),
  url('../components/fonts/PoppinsBlack.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-regular';
  src: url('../components/fonts/PoppinsRegular.woff2') format('woff2'),
    url('../components/fonts/PoppinsRegular.woff') format('woff'),
  url('../components/fonts/PoppinsRegular.otf') format('otf'),
    url('../components/fonts/PoppinsRegular.ttf') format('ttf'),
    url('../components/fonts/PoppinsRegular.eot') format('eot'),
    url('../components/fonts/PoppinsRegular.svg') format('svg');

  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-bold';
  src: url('../components/fonts/PoppinsBold.woff2') format('woff2'),
  url('../components/fonts/PoppinsBold.woff') format('woff');

  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Atomic Marker';
  src: url('../components/fonts/Atomic/AtomicMarker.woff2') format('woff2'),
  url('../components/fonts/Atomic/AtomicMarker.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
*/
@font-face {
  font-family: 'Zuume';
  src: url('../components/fonts/Zumme/Zuume-Bold.woff2') format('woff2'),
  url('../components/fonts/Zumme/Zuume-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
} 
@font-face {
  font-family: "Poppins";
  src: url("fonts/poppins/PoppinsMedium.woff2") format("woff2"),
    url("fonts/poppins/PoppinsMedium.woff") format("woff");
  font-weight: 500;
  font-style: normal;

  font-display: swap;
}
@font-face {
  font-family: "sst";
  src:
  /* .join-content imgurl("fonts/sst/SST-Roman.svg#SST-Roman") format("svg"), */
  /* url("fonts/sst/SST-Roman.ttf") format("ttf"),
  url("fonts/sst/SST-Roman.eot") format("eot"),  */
  url("fonts/sst/SST-Roman.woff2") format("woff2"),
    url("fonts/sst/SST-Roman.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-black";
  src: url("fonts/poppins/PoppinsBlack.woff2") format("woff2"),
    url("fonts/poppins/PoppinsBlack.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-regular";
  src: url("fonts/poppins/PoppinsRegular.svg#PoppinsRegular") format("svg"),
    url("fonts/poppins/PoppinsRegular.woff2") format("woff2"),
    url("fonts/poppins/PoppinsRegular.woff") format("woff"),
    url("fonts/poppins/PoppinsRegular.otf") format("otf"),
    url("fonts/poppins/PoppinsRegular.ttf") format("ttf"),
    url("fonts/poppins/PoppinsRegular.eot") format("eot");

  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-bold";
  src: url("fonts/poppins/PoppinsBold.woff2") format("woff2"),
    url("fonts/poppins/PoppinsBold.woff") format("woff");

  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Atomic Marker";
  src: url("fonts/Atomic/AtomicMarker.woff2") format("woff2"),
    url("fonts/Atomic/AtomicMarker.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Zuume";
  src: url("https://stage-forthemusic.electronics.sony.com/fonts/Zumme/Zuume-Bold.woff2") format("woff2"),
    url("https://stage-forthemusic.electronics.sony.com/fonts/Zumme/Zuume-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

html {
  overflow-x: hidden !important; 

}
.max-width {
  max-width: 1920px;
  margin: 0 auto;
}
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding-bottom: env(safe-area-inset-bottom);

  margin-bottom: 48px;
  margin: 0 auto;
  overflow-x: hidden;
  padding-right: 0px !important;
  font-family: "Poppins";
}
.MuiPaper-root.MuiDialog-paper {
  overflow: hidden !important;
}
.font-SST-14 {
  font-family: "sst";
  font-size: 16px;
  line-height: 20px;
}

.font-Zuume-56 {
  font-family: "Zuume";
  font-size: 56px;
}

.font-Zuume-72 {
  font-family: "Zuume";
  font-size: 72px;
  line-height: 60px;
}

.font-poppins-black {
  font-family: "Poppins-black";
  font-size: 22px;
}

.font-poppins-22 {
  font-size: 22px;
  line-height: 26px;
  font-family: "Poppins-bold";
}

.font-poppins-bold {
  font-size: 26px;
  line-height: 28px;
  font-family: "Poppins-bold";
}

.font-poppins-regular {
  font-size: 16px;
  font-family: "Poppins-regular";
  font-weight:normal;
  line-height: 20px;
}
.form_check .MuiButtonBase-root {
  padding: 0;
}
.MuiCheckbox-root {
  color: #fff !important;
}
.MuiCircularProgress-root {
  width: 20px !important;
  height: 20px !important;
  color: #fff !important;
}
.font-Zuume-120 {
  line-height: 100px;
  font-family: "Zuume";
  font-size: 120px;
}

.font-atomic-110 {
  font-family: "Atomic Marker";
  font-size: 110px;
  line-height: 125px;
}

.font-atomic-large {
  font-family: "Atomic Marker";
  font-size: 65px;
  line-height: 60px;
}

.subscribe-disable {
  background-color: #808080;
  color: #cccccc;
  border: none;
  padding: 16px 24px;
  font-size: 16px;
  line-height: 24px;
  font-family: "Poppins-regular";
  border-radius: 30px;
}
h1,
h2,
h3,
h4,
h5,
a,
ul,
li,
p {
  list-style-type: none;

  margin: 0;
  text-decoration: none;
  padding: 0;
}
.abc .MuiDialog-paper {
  border-radius: 16px;
}
button,
a {
  text-transform: capitalize;
  cursor: pointer;
}



@media screen and (max-width: 1250px) {
  .font-Zuume-120 {
    line-height: 75px;
    font-size: 90px;
  }

  .font-atomic-110 {
    font-size: 90px;
    line-height: 100px;
  }

  .videoContainer video {
    height: 100vh !important;
  }
}
.skip-btn-text{
  text-decoration: underline;
}

@media screen and (max-width: 992px) {
  .font-Zuume-120 {
    line-height: 66px;
    font-size: 68px;
  }
  .card-round .benifits-img .circle-div {
    height: 284px;
    width: 285px;
    position: absolute;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    right: -31px;
    top: -117px;
  }

  .font-poppins-regular {
    font-size: 14px;
  }

  .font-Zuume-72 {
    font-size: 64px;
  }

  .font-atomic-large {
    font-size: 54px;
    line-height: 50px;
  }
}

input:-internal-autofill-selected {
  background-color: transparent;
}

.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 14px;
}

.form__field {
  font-family: inherit;
  width: 506px;
  border: 0;
  border-bottom: 1px solid #9b9b9b;
  outline: 0;
  font-size: 16px;
  border-radius: 0px;
  color: #fff;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}

.form__field::placeholder {
  color: transparent;
}

.form__field:placeholder-shown ~ .form__label {
  font-size: 16px;
  cursor: text;
  top: 20px;
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 12px;
  color: #fff;
}

.form__field:focus {
  padding-bottom: 6px;
  border-width: 1px;
  border-image: linear-gradient(to right, #ffff, #ffff);
  border-image-slice: 1;
}

.form__field:focus ~ .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 0.8rem;
  color: #fff;
}

/* reset input */
.form__field:required,
.form__field:invalid {
  box-shadow: none;
}

/* extra Popup */
.slider-popup .MuiDialog-container .MuiPaper-root {
  background: transparent;
  box-shadow: none;
  overflow: hidden;
}

@media screen and (max-width: 1400px) {
  .slider-popup {
    /*height: 65vh !important;*/
  }
}

.MuiBackdrop-root {
  background-color: transparent !important;
}
.JoinNow-BENIFITS {
  margin: 0 auto;
  background: #000;
  color: #ffff;
  padding: 16px 24px 16px 24px;
  width: auto;
  width: 135px;
  border-radius: 75px;
  margin-top: 64px;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 1024px) {
  .form__field {
    width: 100% !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1024px) {
   

}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .subcontent-explorer {
    padding-left: 50px;
  }
  #products .slider-bulltes {
    padding: 0px 36px !important;
  }

  .view-all-div { 
    padding:0px 36px 48px 36px !important
  }
  #products .swiper-Nav  .slider-bulltes{
    padding: 0px 36px !important;
  }

}
@media screen and (min-width: 768px) and (max-height: 1024px) {
  
}

.slider-bulltes button:hover{
  background-color: #000 !important;
}
.slider-bulltes button:hover svg{
  
  color: #fff !important;
}
@media screen and (min-width: 1024px) and (max-height: 1400px) {
   
}
.slider-bulltes {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

 

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
button,
a {
  cursor: pointer;
}
.view-text:hover{
  text-decoration: underline;
}
@media screen and (max-width: 767px) {
  
  .form__group .react-tel-input{
    width: 80% !important;
  }
  .form__group select{
    width: 25% !important;

  }
  .react-tel-input .form-control{
    width: 100% !important;
  }
  .skip-btn-text{
    text-decoration: none;
  }
  .font-SST-14 {
    font-family: "sst";
    font-size: 14px;
    line-height: 16px;
  }
  .card-round .benifits-img .circle-div {
    height: 327px;
    width: 306px;
    position: absolute;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    right: -19px;
  }
   
  #bannervideo .home-text {
    width: 95% !important;
    padding-right: 0 !important;
    padding-left: 16px !important;
  }
  .form__field:placeholder-shown ~ .form__label,
  .form__label {
    font-size: 14px;
    line-height: 16px;
  }
  .form__field {
    font-size: 16px;
    line-height: 16px;
  }
  .slider-popup {
    /* height: 75vh !important;
    top: inherit !important; */
  }
  
  #products .swiper-horizontal{
    padding-right: 0px !important;
  }

  .card-stack {
    left: 0px !important;
  }

  .card-stack {
    width: 100% !important;
    
  }


  .font-Zuume-120 {
    font-size: 44px;
    line-height: 40px;
  }

  .form__field {
    width: 100%;
  }
  #products .swiper-Nav  .slider-bulltes{
    padding: 0px 16px !important;
  }
  #products .view-all-div {
    padding:0px 16px 48px 16px !important
  }
}
@media screen and (max-width: 375px) {
  .card-round .benifits-img .circle-div {
    height: 355px;
    width: 356px;
    top: -125px;
  }
  .circle-img {
    height: 100%;
    top: 52px;
  }
}

@media screen and (max-width: 1440px) {
  .form__field {
    width: 100%;
  }
}

@media screen and (min-width: 1701px) and (max-width: 1800px) {
  .benifits-img {
    height: 355px !important;
  }
  .circle-div {
    height: 478px !important;
    width: 451px !important;
  }
  .circle-img {
    height: 100%;
    top: 85px;
  }
}
@media screen and (min-width: 1441px) and (max-width: 1700px) {
  .benifits-img {
    height: 390px !important;
  }
  .circle-div {
    height: 501px !important;
    width: 470px !important;
  }
}

@media screen and (min-width: 1801px) {
  .benifits-img {
    height: 350px !important
  }
  .circle-div {
    height: 533px !important;
    width: 508px !important;
    right: -25px !important;
    top: -241px !important;
  }
  .circle-img {
    height: 100%;
    top: 110px !important;
  }

  .card-gradient .benifits-img {
    display: flex;
    align-items: center;
  }
}

@media screen and (max-device-width: 667px) and (orientation: landscape) {
  .card-round .benifits-img .circle-div {
    height: 288px;
    width: 261px;
  }
}
@media only screen and (min-device-width: 844px) and (max-device-width: 980px) and (orientation: landscape) {
  .card-stack {
      width: 100%;
      height: 310px !important;
    }
  }

@media screen and (max-device-width: 980px) and (orientation: landscape) {
  .slider-popup .MuiDialog-container .MuiPaper-root{
    /* overflow-y: scroll  !important; */
  }
.down-arrow-playlist svg{
  font-size: 22px;
}

}

.slider-bulltes ul{
  display: flex;
  align-items: center;
}
.slider-bulltes ul li span {
  background-color: green;
}
.slider-bulltes ul li {
  margin:0px 0.3em
}
.swiper-Nav .MuiTouchRipple-root {
  /* border: 1px solid #000 !important; */
}
.MuiButtonBase-root-MuiButton-root{
  border: none !important;
}
.slider-bulltes  ul li span {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 4px;
  height: 4px;
  font-size: 0px;
  background: rgb(122, 122, 122);
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  outline: none;
  border-radius: 50%;
  margin-right:5px ;
  transition: all 0.2s ease-in-out 0s;
}
.slider-bulltes ul li span.is-active_dark {
  background-color: rgb(0, 0, 0);
  transform: scale(1.4);
  height: 8px !important;
  width: 8px !important;
  display: table;
  border-radius: 50%;

}
#products .slider-bulltes {
  padding: 0px 80px !important;
}

@media screen and (max-device-width: 767px) and (orientation: landscape) {
 
 .join-form .slider-header.drawer {
    width: 96% !important;
  }
}
@media screen and (max-device-width: 900px) and (orientation: landscape) {
  .subscribe-disable {
    padding: 8px 16px;
    font-size: 14px;
  }
  #products .slider-bulltes{
    padding: 0 16px !important
  }
  .join-content img {
    width: 100px;
  }
  .JoinNowASD {
    padding: 8px 16px;
  }
  .play-next-prev .hero-P-pause-btn {
    width: 78px !important;
    padding: 10px 2px;
  }
  .play-next-prev{
    margin-bottom: 10px !important;
  }
}

/* changes by muthu and gowtham */

@media screen and (min-width: 320px) and (max-width: 415px) {
  .submit-form-btn {
    margin-bottom: 100px;
  }
  .insider-form-div .start-text .joinmusic-text {
    width: auto !important;
  }
  .join-text {
    margin-top: 50px !important;
  }
  
  @media screen and (min-width: 375px) and (max-width: 413px) {
    .submit-form-btn {
      margin-bottom: 100px;
    }
    .joinmusic-text {
      width: auto !important;
    }
    
  }
}
@media screen and (min-width: 375px) and (max-width: 560px) {
  .join-text {
    margin-top: 50px !important;
  }
  
}
.slider-bulltes div + div > button.button-hovered:active{
  background-color: #000 !important;
}

.slider-bulltes div + div > button.button-hovered:active svg{
  
  color: #fff !important;
}

.slider-bulltes div + div > button.button-hovered{

  background-color: #fff !important;

}

.slider-bulltes div + div > button.button-hovered svg{

 

  color: #000 !important;

}

@media screen and (min-width: 768px) {
  .benifits-pop-up .circle-div-join-web{
    /* width: 650px !important;
    height: 650px !important; */
    position: absolute !important;
    border-radius: 50% !important;
    overflow: hidden !important;
    display: block !important;
    position: absolute !important; 
    /* right: -88px !important;
    bottom: -161px !important; */
  }
}
.slider-popup.jointoForm > div + div + div > div {
  /* overflow-y: scroll !important; */
  border-radius: 50px 50px 0px 0px;
}
@media screen and (min-width:390px) and  (max-width: 449px) {
    .jointoForm form.insider-form.insider-form-container-pop-up{
      height:auto !important;
    }

}
/* @media screen and (max-width: 767px) {
  _::-webkit-full-page-media, _:future, :root .safari_only {
      padding-bottom: 25px; 
  }
} */
.jointoForm div.drawer {
  position: fixed;
  background-color: black;
  width: 92% !important;
  border-top: 1px solid black;
  z-index: 999;
}
.jointoForm div.drawer + p.font-Zuume-56 {
  padding-top: 70px;
}
/* .jointoForm .insider-form.insider-form-container-pop-up{
  display: -webkit-box;
} */

.jointoForm .confirm-text{
-webkit-text-size-adjust: 100%;
}

.benifits-pop-up .dummy-text-benefits{
  padding: 35px 35px 0px 35px;
  visibility: hidden;
}

.benefits-back-btn{
  position: absolute;
  z-index: 1;
  width: calc(100% - 40px);
  width: 100%;
  background-color: black;
  
  top: 0px;
  display: block; 
  color: white;
}
.benefits-back-btn > button{
display: block;
/* margin-top: 6px; */
padding: 0;
}
.benefits-back-btn > button.join-text{
  display: block;
  }

  @media screen and (min-width: 1441px){
    .form__field{
      width: 100%;
    }
  }
  @media screen and (min-width: 561px){
  .join-text {
      margin-top: 15px !important;
  }
}
.drawer-close-icon .Slide-Swiper-div{
display: flex;
}

.drawer-close-icon {
  position: relative;
    width: 100%;
}

.static-flag{
  display: flex;
  width: 73%;
  align-items: center;
  padding-bottom: 3px;
  /* border-bottom: 1px solid #9b9b9b; */
}

.select-field .static-flag .MuiTextField-root {
  /* min-width: 160px !important; */
}
.flag-code img{
  margin-top: 0px !important;
  height: 15px;
  width: 20px;
  margin-right: 3px;
}
.static-flag input{
  background: transparent;
  padding:0px ; 
  border: none;
  font-size: 16px;
  color:#fff;
  font-family: 'Poppins-regular';
  width: 100%;
}
.flag-code{
  display: flex;
  margin-right:3px;
  align-items: center;
}
.react-tel-input .selected-flag{
  background-color: #000 !important;
}

.react-tel-input .form-control{
  width: 100% !important;
    background: transparent !important;
    border-bottom: 1px solid #fff !important;
    color:  #fff !important;

}
.react-tel-input .selected-flag{
  
  border-radius: 0PX !important;
}

.react-tel-input .form-control{
  border-bottom: 1px solid #9b9b9b !important;
  border:  1px solid transparent !important;
}
.react-tel-input{
  display: flex;
  align-items: flex-end;
  border-bottom: 1px solid #9b9b9b!important;
}

.react-tel-input .flag-dropdown{
  border: none  !important;
} 
.react-tel-input .form-control{
  font-size: 16px !important;
  font-family: 'Poppins-regular';

}
.react-tel-input .country-list{
  background-color: #000 !important;
  max-height:90px !important
}
.react-tel-input .country-list .country{
  border-bottom: 1px solid grey;
}
.react-tel-input .country-list .country.highlight {
  background-color: #272727 !important;
}
.react-tel-input .country-list .country-name{
  font-family: 'Poppins-regular';
  font-size: 12px !important;

}

.react-tel-input .country-list .country:hover {
 transition: 0.3s;
  background-color: #363333 !important;
}


.react-tel-input{
  width: 506px  !important;
}
.react-tel-input .flag-dropdown{
  background-color: #000;
  left:6px !important;
}

.form__group select option{
  font-family: 'Poppins-regular';
  font-size: 16px;
}
.form__group select{
  margin-right: 10px;
  background: transparent;
  color: #fff;
  border: none;
  font-family: 'Poppins-regular';
  font-size: 14px;
  /* box-shadow: 0px 0px 5px #2a2727; */
  /* border-radius: 5px; */
}
.select-field{
  display: flex;
  border-bottom: 1px solid #9b9b9b !important;
  width: 100%;
  flex-direction: column;
}

.form__group select option{
  background:#000;
  font-size: 16px;


}

.select-field .MuiFormControl-root{
  margin: 0px;
}
 

.select-field .MuiFormControl-root,
.select-field .MuiFormLabel,
.select-field  svg ,.select-field label{
  color: #fff !important;
  font-family: 'Poppins-regular'; 

}

.select-field .MuiFormControl-root{
  /* border-bottom: 1px solid #9b9b9b !important; */

  min-width: auto !important;
}
/* .select-field .MuiInputBase-root{
  border-bottom: 1px solid #9b9b9b !important;
} */
.select-field .MuiSelect-select{
  /* border-bottom: 1px solid #fff!important; */

}

.select-field .MuiInputBase-root-MuiInput-root-MuiSelect-root:after{
  border: none !important;
}
.select-field .MuiSelect-select{
  color: #fff !important;
  font-family: 'Poppins-regular';
}

.MuiInputBase-root-MuiInput-root:after{
  border-bottom:32px solid green  !important;
}
.flag-code{
  width: 45px;
}


.select-field .static-flag .MuiTextField-root{
width: calc(100% - 45px);
}

@media screen and (min-width: 561px) and (max-device-width: 980px) and (orientation: landscape) {
  .select-field .MuiFormControl-root{
    min-width: auto !important;
  }
  .benifits-pop-up .dummy-text-benefits{
    padding: 0px;
  }
  .select-field .MuiSelect-select{
    font-size: 13px;
    padding-bottom: 0px;
  }
  .static-flag{
    width: 70%;
  }
  .static-flag input,.flag-code p{
    font-size: 13px;
  }
  .form__group select{
    font-size: 16px;
  }
  .view-all-div{
    padding:0px 34px 36px 34px  !important;
  }
  
}
.select-field select{
  -webkit-appearance: listbox !important;
  /*webkit browsers */
  -moz-appearance: listbox !important;
  /*Firefox */
  appearance: listbox !important;
  background-color: rgba(255, 255, 255, 0.01);
  
}

#products .swiper-horizontal{
  padding-right: 80px;
}
@media screen and (max-width: 768px) {
  .static-flag input,.flag-code p{
    font-size: 16px;
  }
  .form__group select{
    font-size: 14px;
  }
}

.select-field .Mui-focused::after,.select-field .MuiInputBase-root:after{
  border-bottom: none !important;
}


div {
    -webkit-tap-highlight-color: transparent;
}